// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-artists-js": () => import("./../../../src/pages/en/artists.js" /* webpackChunkName: "component---src-pages-en-artists-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-exhibitions-js": () => import("./../../../src/pages/en/exhibitions.js" /* webpackChunkName: "component---src-pages-en-exhibitions-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-news-js": () => import("./../../../src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-podcasts-js": () => import("./../../../src/pages/en/podcasts.js" /* webpackChunkName: "component---src-pages-en-podcasts-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-en-publications-js": () => import("./../../../src/pages/en/publications.js" /* webpackChunkName: "component---src-pages-en-publications-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-podcasts-js": () => import("./../../../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/Artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-en-artist-js": () => import("./../../../src/templates/en/Artist.js" /* webpackChunkName: "component---src-templates-en-artist-js" */),
  "component---src-templates-en-exhibition-js": () => import("./../../../src/templates/en/Exhibition.js" /* webpackChunkName: "component---src-templates-en-exhibition-js" */),
  "component---src-templates-en-new-js": () => import("./../../../src/templates/en/New.js" /* webpackChunkName: "component---src-templates-en-new-js" */),
  "component---src-templates-en-podcast-js": () => import("./../../../src/templates/en/Podcast.js" /* webpackChunkName: "component---src-templates-en-podcast-js" */),
  "component---src-templates-en-publication-js": () => import("./../../../src/templates/en/Publication.js" /* webpackChunkName: "component---src-templates-en-publication-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/Exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-new-js": () => import("./../../../src/templates/New.js" /* webpackChunkName: "component---src-templates-new-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/Podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/Publication.js" /* webpackChunkName: "component---src-templates-publication-js" */)
}

